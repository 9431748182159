<template>
  <div>
    <div class="fs-container">
      <div class="fs-section fs-choose">
        <div class="fs-inr choose-inr">
          <div class="fs-pic choose-pic">
            <img loading="lazy" src="@/assets/images/home/choose-nummber.png" alt="Chọn số - Mua SIM" />
            <div class="choose-pic-087">
              <img loading="lazy" src="@/assets/images/home/087.png" alt="087" />
            </div>
            <div class="choose-pic-heart">
              <img loading="lazy" src="@/assets/images/home/heart.png" alt="heart" />
            </div>
            <div class="choose-pic-mountain">
              <img loading="lazy" src="@/assets/images/home/mountain.png" alt="mountain" />
            </div>
            <div class="choose-pic-user">
              <img loading="lazy" src="@/assets/images/home/user.png" alt="user" />
            </div>
          </div>
          <div class="fs-txt choose-txt font-48">
            <h2>{{ $t("HomePage.PickNumberBuySim") }}</h2>
            <p>{{ $t("HomePage.BuySimSlogan") }}</p>
            <div class="fs-buts">
              <router-link to="/mua-hang" class="fs-but search-mask">
                {{ $t("HomePage.Pick the number now") }}
              </router-link>
              <router-link to="/mua-hang?simCategory=40" class="m-lg-1 mt-2 mt-lg-0 fs-but search-mask">
                {{ $t("Chọn số thường (- 50%)") }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="fs-section fs-banner">
        <div class="fs-inr banner-inr">
          <div class="banner-slider swiper-container">
            <swiper :key="Banners.length" :slides-per-view="1" :centeredSlides="true" :autoplay="{
              delay: 3000,
              disableOnInteraction: false,
            }" :pagination="{
                 clickable: true,
            }" :allowTouchMove="false" :navigation="false" :space-between="50" @swiper="onSwiper" @slideChange="onSlideChange"
                    :loop="false">
              <swiper-slide v-for="b in Banners" :key="b.id">
                <a class="fs-pic" :href="b.Url"><img loading="lazy" alt="Banner Mobile" class="fs-sp"
                                                     :src="b.ThumbnailMobile"/></a>
                <a class="fs-pic desktop-img" :href="b.Url"><img loading="lazy" alt="Banner Desktop" class="fs-pc"
                                                                 :src="b.Thumbnail"/></a>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
      <!---->


      <div class="fs-section fs-pack bg-white">
        <div class="fs-inr pack-inr">
          <div class="fs-txt font-48">
            <h2>
              {{ $t('DevicePage.PhoneDevice') }}<br class="d-md-none" />
              <router-link :to="{ name: 'products-home' }" class="d-none d-xl-block fs-but search-mask float-right ml-3">
                {{ $t('DeviceHome.ViewAll') }}
              </router-link>
            </h2>
            <p>{{ $t('DeviceHome.EnjoyTheSpecialBenefitsOfITel') }}</p>
          </div>
        </div>
        <div class="fs-inr pack-inr product">
          <b-carousel class="w-100" indicators controls>
            <b-carousel-slide v-for="(ps, stt) in products" :key="stt">
              <template v-slot:img>
                <b-row class="pl-xl-3 pr-xl-3 pl-md-3 pr-md-3">
                  <b-col md="6" sm="12" lg="6" xl="4" class="mb-3 p-1 pt-3" v-for="(p, stt1) in ps" :key="stt1">
                    <div class="card p-0 shop_home_box">
                      <!-- <div class="card-installment">
                        <div class="card-installment-text">
                          {{ $t('DevicePage.InstallmentPercent') }}
                        </div>
                      </div> -->
                      <router-link :to="'/shop/san-pham/' + p.origin_type + '/' + p.categories[0].slug + '/' + p.meta.slug"
                        class="card-body" @click="BuyNow(p)" role="button" :title="p.product_name">
                        <div class="footer">
                          <span class="promo" v-if="p.discount_info">{{ $t('DevicePage.PricesAreValid') }}
                            {{ p.discount_info }}</span>
                          <span class="price">{{ p.price | number }}đ</span>
                          <span class="base_price" v-if="p.discount_info || p.base_price > p.price">{{ p.base_price |
                            number
                          }}đ</span>
                          <span role="button" class="buy-now" @click="BuyNow(p)">{{ $t('DevicePage.BuyNow') }}</span>
                        </div>
                        <img :src="p.image_url" />
                        <div class="title1">{{ p.meta.supplier }}</div>
                        <div class="title2" :class="p.origin_type == 'baseus' ? 'title2-baseus' : 'title2'">
                          <span v-html="p.product_name"></span>
                        </div>
                        <!-- <div class="features" v-if="p.origin_type == 'baseus'">
                          <span v-for="(c, stt) in p.variants" :key="stt">{{ c.title }}</span>
                        </div> -->
                        <div class="features">
                          <span v-for="(c, stt) in p.config.filter(i => i.on_top == 1)" :key="stt">{{
                            p.attributes[c.code]
                          }}</span>
                        </div>
                      </router-link>
                    </div>
                  </b-col>
                </b-row>
              </template>
            </b-carousel-slide>
          </b-carousel>
          <div class="text-center">
            <router-link :to="{ name: 'products-home' }" class="d-xl-none btn btn-primary ml-auto mr-auto mb-0 pb-0">
              {{ $t('DeviceHome.ViewAll') }}
            </router-link>
          </div>
        </div>
      </div>

      <!--<div class="fs-section fs-i-news">
        <div class="fs-inr i-news-inr">
          <div class="fs-box i-news-infos">
            <div class="fs-txt">
              <h2>i-NEWS</h2>
              <p>{{ $t("HomePage.Slogan") }}</p>
            </div>
            <div class="fs-pic">
              <img
                loading="lazy"
                src="@/assets/images/home/i-news.png"
                :alt="$t('HomePage.Slogan')"
              />
            </div>
            <div class="fs-buts">
              <router-link :to="$t('Route.INews')" class="fs-but bg-white">{{
                $t("HomePage.See more")
              }}</router-link>
            </div>
          </div>
          <div class="fs-box i-news-videos" v-if="Blogs.length > 0">
            <template v-for="b in Blogs">
              <div
                v-if="b.IsTypeVideo"
                class="video-box type-video"
                :key="b.id"
              >
                <div
                  class="fs-bg cmBg"
                  :style="'background-image: url(\'' + b.Thumbnail + '\')'"
                ></div>
                <div class="news-type">I-VIDEO</div>
                <div
                  class="video-but open-video-pop"
                  @click="openVideo(b.VideoUrl)"
                  :data-video="b.VideoUrl"
                ></div>
                <div class="fs-txt">
                  <h3>{{ b.Title }}</h3>
                </div>
              </div>

              <a v-if="!b.IsTypeVideo" class="video-box" :key="b.id">
                <div
                  class="fs-bg cmBg"
                  :style="'background-image: url(\'' + b.Thumbnail + '\')'"
                ></div>
                <div class="news-type">I-MAGAZINE</div>
                <div class="fs-txt">
                  <h3>{{ b.Title }}</h3>
                </div>
              </a>
            </template>
          </div>
        </div>
      </div>-->

      <div class="fs-section fs-itshop">
        <div class="fs-inr pack-inr">
          <div class="fs-txt font-48">
            <h2><a href="/itshop/tat-ca">{{ $t("ITEL SHOP") }}</a><br class="d-md-none">
              <router-link :to="{ name: 'products-home', params: { Slug: 'dien-thoai-thiet-bi' } }"
                class="hidden d-none d-xl-block fs-but search-mask float-right ml-3">
                {{ $t("Xem tất cả") }}
              </router-link>
            </h2>
            <p>{{ $t("Tiện ích Max đỉnh, Shopping Max mê") }}</p>
          </div>
        </div>
        <div class="fs-inr pack-inr group-product">
          <div class="row">
            <div class="col-6">
              <a href="/itshop/tai-chinh-bao-hiem">
                <div class="card p-0">
                  <img class="img_bg" src="@/assets/images/shop/finance_1.svg" />
                  <img class="img_main" src="@/assets/images/shop/finance.svg" />
                  <div class="header1 bank"><span class="text-primary">T</span>ài chính-Bảo hiểm</div>
                  <div class="header2 bank">Mở thẻ ngân hàng, Mua bảo hiểm</div>
                  <div class="header3 bank">Mở thẻ ngân hàng, Mua bảo hiểm</div>
                  <div class="button">Xem thêm</div>
                </div>
              </a>
            </div>
            <div class="col-6">
              <a href="/itshop/du-lich">
                <div class="card p-0">
                  <img class="img_bg" src="@/assets/images/shop/travel_1.svg" />
                  <img class="img_main" src="@/assets/images/shop/travel.svg" />
                  <div class="header1"><span class="text-primary">D</span>u lịch đi lại</div>
                  <div class="header2">Di chuyển thuận tiện hơn</div>
                  <div class="header3">Di chuyển thuận tiện hơn</div>
                  <div class="button">Xem thêm</div>
                </div>
              </a>
            </div>
            <div class="col-6">
              <a href="/itshop/mua-sam">
                <div class="card p-0">
                  <img class="img_bg" src="@/assets/images/shop/shopping_1.svg" />
                  <img class="img_main" src="@/assets/images/shop/shopping.svg" />
                  <div class="header1"><span class="text-primary">M</span>ua sắm</div>
                  <div class="header2">Shopping max mê</div>
                  <div class="header3">Shopping max mê</div>
                  <div class="button">Xem thêm</div>
                </div>
              </a>
            </div>
            <div class="col-6">
              <a href="/itshop/an-uong">
                <div class="card p-0">
                  <img class="img_bg" src="@/assets/images/shop/food_1.svg" />
                  <img class="img_main" src="@/assets/images/shop/food.svg" />
                  <div class="header1"><span class="text-primary">Ă</span>n uống</div>
                  <div class="header2">Voucher max sướng</div>
                  <div class="header3">Voucher max sướng</div>
                  <div class="button">Xem thêm</div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <!--<div class="fs-inr pack-inr product">
          <div class="row">
            <div class="col-6 col-lg-4 col-md-6">
              <div class="card p-0">

                <a target="_blank" href="/vietlott">
                  <div class="card-body">
                    <img src="@/assets/images/shop/vietlott.png" />
                  </div>
                </a>
                <div class="card-footer bg-primary">Vé số Vietlott</div>
              </div>
            </div>
            <div class="col-6 col-lg-4 col-md-6">
              <div class="card p-0">
                <a target="_blank" :href="vntrip">
                  <div class="card-body">
                    <img src="@/assets/images/shop/vntrip.png"/>
                  </div>
                </a>
                <div class="card-footer bg-primary">Du lịch</div>
              </div>
            </div>
            <div class="col-6 col-lg-4 col-md-6">
              <div class="card p-0">
                <a href="/vexere"><div class="card-body">
                  <img src="@/assets/images/shop/vexere.png"/>
                </div></a>
                <div class="card-footer bg-primary">Di chuyển</div>
              </div>
            </div>
            <div class="col-6 col-lg-4 col-md-6">
              <div class="card p-0">
                <a @click="$bvModal.show('modal-ocb')"><div class="card-body">
                  <img src="@/assets/images/shop/ocb.png"/>
                </div></a>
                <div class="card-footer bg-primary">Mở tài khoản ngân hàng</div>
              </div>
            </div>
            <div class="col-6 col-lg-4 col-md-6">
              <div class="card p-0">
                <div class="card-body">
                  <a href="/abtrip"><img src="@/assets/images/services/abtrip.png" /></a>
                </div>
                <div class="card-footer bg-primary">Vé máy bay</div>
              </div>
            </div>
            <div class="col-6 col-lg-4 col-md-6">
              <div class="card p-0">
                <div class="card-body">
                  <a href="/vpbank"><img src="@/assets/images/services/VPBank.svg" alt="icon-notify"></a>
                </div>
                <div class="card-footer bg-primary">Mở thẻ tín dụng VpBank</div>
              </div>
            </div>
          </div>
        </div>-->
      </div>

      <div class="fs-section fs-support">
        <div class="fs-inr support-inr">
          <div class="fs-pic support-pic">
            <img src="@/assets/images/home/gril.png" :alt="$t('HomePage.Customer Support Center')" />
          </div>
          <div class="fs-txt support-txt font-48">
            <h2 v-html="$t('HomePage.Customer Support Center2')"></h2>
            <p v-html="$t('HomePage.Customer Support Center3')"></p>
            <div class="fs-buts">
              <router-link :to="$t('Route.Support')" class="fs-but chat-mask">{{
                $t("HomePage.Support2")
              }}</router-link>
            </div>
            <div class="fs-pic like-pic">
              <img src="@/assets/images/home/theo-la-thich.png" :alt="$t('HomePage.Slogan2')" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Video Popup-->
    <div :class="'modal ' + modalClass" id="video-modal">
      <div class="overlay" @click="closeVideo()"></div>
      <div class="modal-inner">
        <a class="close-button" @click="closeVideo()"></a>
        <iframe :src="videoId" allow="autoplay; encrypted-media" allowfullscreen></iframe>
      </div>
    </div>
    <!--Video Popup-->

    <div class="faci-box" v-if="ConfigData.length > 0">
      <a class="hotline-faci" :href="
        'tel:' + ConfigData.find((i) => i.DataKey == 'PhoneWebsite').Value
      ">
        <img loading="lazy" src="@/assets/images/sp-chat-tel.png" alt="hot line" />
      </a>
      <a class="chat-faci" href="https://zalo.me/pc" target="_blank" rel="noopener">
        <img loading="lazy" src="@/assets/images/sp-chat-icon.png" alt="hot chat" />
      </a>
    </div>

    <b-modal size="xl" body-class="p-4" hide-header hide-footer id="modal-ocb" title="Thông báo!">
      <div class="row">
        <div class="col-lg-7 col-12">
          <img width="100%" src="@/assets/images/shop/ocb2.png" />
          <h1 class="page-item text-center pt-2 pb-1">MỞ OMNI - NHẬN TIỀN TỨC THÌ</h1>
        </div>
        <div class="ocb col-lg-5 col-12">
          <h1 class="border-bottom mb-1 page-item pb-1 font-weight-bold">THÔNG TIN CHƯƠNG TRÌNH</h1>
          <div class="text" style="max-height:460px; overflow:auto">
            <b>1. Tên chương trình</b>: MỞ OMNI – NHẬN TIỀN TỨC THÌ<br>
            <b>2. Thời gian khuyến mại</b>: Chương trình triển khai từ ngày 01/12/2022 đến ngày 31/12/2022 hoặc đến khi sử
            dụng hết ngân sách nhận thưởng.<br>
            <b>3. Địa bàn (phạm vi) khuyến mại</b>: Toàn quốc.<br>
            <b>4. Đối tượng áp dụng</b>: Khách hàng chưa có tài khoản thanh toán tại OCB.<br>
            <b>5. Nội dung chi tiết thể lệ chương trình</b>:<br>
            <div class="ml-2 text">
              <b>5.1 Điều kiện tham gia</b>:<br>
              <ul>
                <li>Chương trình “Mở OMNI - Nhận tiền tức thì” là chương trình liên kết giữa CTCP Viễn Thông Di Động Đông
                  Dương Telecom (ITEL) với Ngân hàng TMCP Phương Đông (OCB), các ưu đãi đặc biệt dành cho Khách hàng cá
                  nhân mới chưa có thông tin tại OCB.</li>
                <li>Các bước tham gia chương trình:</li>
              </ul>
              <b>Bước 1</b>: Khách hàng tải và cài đặt ứng dụng Ngân hàng số OCB OMNI theo 3 cách sau: <br>
              <div class="pl-2">
                Cách 1: Click vào đường link: <a
                  href="https://ocbomni.onelink.me/RE8p/iTel"><b>https://ocbomni.onelink.me/RE8p/iTel</b></a><br>
                Cách 2: Click vào hưỡng dẫn trên bài viết giới thiệu về chương trình.<br>
                Cách 3: Scan mã QR code:<br>
                <img src="@/assets/images/services/ocb_qr.png" width="50%" /><br>
                <b>Bước 2</b>: Thực hiện đăng ký tài khoản thanh toán tại OCB trên ứng dụng OCB OMNI bằng công nghệ định
                danh điện tử (eKYC) 100% online.<br>
                <b>Bước 3</b>: Sau khi hoàn tất đăng ký, đăng nhập OCB OMNI và thực hiện ít nhất 1 (một) giao dịch thanh
                toán tối thiểu 20.000 VNĐ như thanh toán hóa đơn, nạp tiền điện thoại…hoặc giao dịch chuyển khoản tối
                thiểu <b>100.000 VNĐ</b> trong vòng 30 ngày kể từ ngày đăng ký tài khoản thành công.<br>
                <b>Bước 4</b>: Click vào đường link: <a
                  href="https://myitel.onelink.me/1Wbg/iTelShopOCB"><b>https://myitel.onelink.me/1Wbg/iTelShopOCB</b></a>
                (với những Khách hàng chưa có My iTel) Hoặc quét mã QR-code và thực hiện đăng ký thành công My iTel.<br>
                <img src="@/assets/images/shop/itel_qr.png" width="50%" /><br>
              </div>
              <b>5.2 Cách thức trao thưởng</b><br>
              Khách hàng sẽ nhận được 100.000 VNĐ (một trăm nghìn đồng) vào tài khoản OCB OMNI vừa mở trong vòng 7 ngày
              đến 30 ngày làm việc sau khi khách hàng hoàn tất mở mới và thực hiện giao dịch hợp lệ thành công trên ứng
              dụng Ngân hàng số OCB OMNI.<br>
              <b>5.3 Quy định chương trình</b><br>
              <ul>
                <li>Mỗi khách hàng được tham gia chương trình 01 (một) lần và tính cho giao dịch thỏa điều kiện đầu tiên.
                </li>
                <li>Chương trình có thể kết thúc sớm hơn thời gian dự kiến khi hết ngân sách khuyến mại.</li>
                <li>Thời gian giải quyết khiếu nại là 30 ngày kể từ ngày chương trình kết thúc</li>
              </ul>
            </div>
            <div class="text">
              <b>6. Thông tin liên hệ:</b>
              Mọi thắc mắc liên quan đến chương trình, Quý Khách vui lòng liên hệ Trung tâm dịch vụ Khách hàng OCB:
              <ul>
                <li>Hội sở chính: 41&45 Lê Duẩn, Phường Bến Nghé, Quận 1, Tp.HCM</li>
                <li>Hotline: 1800 6678 (Miễn phí 24/7)</li>
                <li>Email: dvkh@ocb.com.vn</li>
              </ul>
            </div>
          </div>
          <a href="https://ocbomni.onelink.me/RE8p/iTel" class="btn btn-primary btn-sm w-100 mt-2">Tiếp tục</a>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { Navigation, Pagination, Autoplay } from 'swiper';
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2';
import useJwt from '@/auth/jwt/useJwt';
import EventBus from '@/global-components';
import {
  BCarousel,
  BCarouselSlide,
  BRow,
  BCol,
  BCard,
  BCardTitle,
} from 'bootstrap-vue';
import { OriginType } from '@/libs/acl/config';
// Import Swiper styles
import "swiper/swiper-bundle.css";
SwiperCore.use([Navigation, Pagination, Autoplay]);

export default {
  components: {
    Swiper,
    SwiperSlide,
    BCarousel,
    BCarouselSlide,
    BRow,
    BCol,
    BCard,
    BCardTitle,
  },
  data() {
    return {
      Banners: [],
      Blogs: [],
      ConfigData: [],
      videoId: "",
      modalClass: "",
      isMenu: false,
      products: [],
      vw: window.innerWidth,
      vntrip: ""
    };
  },
  setup() {
    const onSwiper = (swiper) => {
      //console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
    };
  },
  mounted() {
    // insider
    window.insider_object = window.insider_object || {};
    window.insider_object.page = {
      type: "Home",
    };
    useJwt.get("vntrip/getlink/flight").then((response) => { this.vntrip = response.data.result.replace("flight", "") });

    this.setAnimation();
    this.fetch();
    var t_ = this;
    EventBus.$on("CHANGE_LANG", function (payLoad) {
      t_.fetch();
    });
    document.addEventListener("scroll", (e) => {
      this.setAnimation();
    });
  },
  methods: {
    //mua của baseus  gán thêm originType
    BuyNow(p) {
      this.$router.push({ name: 'product-detail', params: { originType: p.origin_type, slugPath: p.categories[0].slug, Slug: p.meta.slug } });
    },
    fetch() {
      this.loading(true);
      useJwt
        .post("getMasterData/home", {
          columnFilters: {},
          sort: [],
          page: 1,
          pageSize: 1000,
          lang: this.$l,
        })
        .then((response) => {
          //console.log(response.data.result.Banners);
          this.Banners =
            response.data.result.Banners
          this.Blogs = response.data.result.Blogs;
          this.ConfigData = response.data.result.ConfigData;
          this.loading(false);
        })
        .catch((err) => {
          this.loading(false);
          this.$toast.error(
            "Có lỗi trong khi lấy dữ liệu, Quý khách vui lòng thử lại!",
            { icon: true, closeButton: "button" }
          );
        });
      this.$store.dispatch("products/fetchProducts", this);
    },
    openVideo(url) {
      this.modalClass = "modal__open";
      this.videoId =
        "https://www.youtube.com/embed/" +
        url +
        "?rel=0&amp;autoplay=1&amp;playsinline=1";
    },
    closeVideo() {
      this.videoId = "";
      this.modalClass = "";
    },
    setAnimation() {
      const windowH = window.screen.height;
      const headH = document.querySelector(".fs-header").clientHeight;
      [].slice
        .call(
          document.querySelectorAll(
            ".fs-banner, .choose-pic, .choose-txt, .pack-txt, .pack-info, .i-news-infos, .video-box, .support-pic, .support-txt, .like-pic, .welcome-inr .fs-txt, .welcome-inr .fs-pic, .why-inr .fs-pic, .item-reseaon, .hand-pic, .hand-txt,.about-item, .block-title, .mission-item, .value-item, .promotion-video-box, .vision-inr, .fs-distributions .fs-inr"
          )
        )
        .forEach((elm) => {
          const style = window.getComputedStyle(elm, null);
          const elmH = style.getPropertyValue("height");
          const inputT = windowH - elm.getBoundingClientRect().top;
          if (inputT > 0 && inputT < windowH + parseInt(elmH, 10) - headH / 2) {
            elm.classList.add("fs-ani");
          } else {
            elm.classList.remove("fs-ani");
          }
        });
    },
  },
};
</script>

<style>
.fs-itshop {
  padding: 5rem 0 9rem 0;
  background-color: #F7F7F7;
  overflow: hidden;
  margin: 8rem 0;
}

@media screen and (max-width: 450px) {
  .desktop-img {
    display: none;
  }
}

@media screen and (min-width: 451px) {
  .mobile-img {
    display: none;
  }
}

.ocb,
.ocb p,
.ocb ul,
.ocb ul li {
  font-size: 1.4rem;
  line-height: 2.3rem;
}

.ocb ul {
  padding-left: 1.5rem;
  list-style: square;
}

.ocb ul li {
  margin-left: 1.5rem;
}

#modal-ocb___BV_modal_content_ {
  margin-top: 5%;
}

.group-product .card {
  height: 212px;
}

.group-product .card .img_main {
  width: 188px;
  height: 188px;
  margin-left: auto;
  margin-right: 20px;
  margin-top: 20px;
}

.group-product .card .img_bg {
  width: 45px;
  position: absolute;
  top: 20px;
  left: calc(50% - 45px - 32px);
}

.group-product .card .header1 {
  font-weight: 900;
  font-size: 28.6px;
  line-height: 36px;
  text-align: center;
  color: #58595B;
  position: absolute;
  left: 25px;
  bottom: 36px;
}

.group-product .card .header3,
.group-product .card .button {
  display: none;
}

.group-product .card:hover .button {
  width: 113.47px;
  height: 32.73px;
  background: #FFFFFF;
  border-radius: 8.72853px;
  font-weight: 700;
  font-size: 12.7495px;
  line-height: 32.73px;
  text-align: center;
  color: #ED3636;
  position: absolute;
  left: 25px;
  bottom: 23px;
  display: block;
}

.group-product .card .header2,
.group-product .card .header3 {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #58595B;
  position: absolute;
  left: 25px;
  bottom: 23px;
}

.group-product .card:hover {
  background: linear-gradient(224.68deg, #F48181 9.52%, #F95454 28.68%, #E21A1A 95.11%);
  border: none !important;
  transform: scale(1.03);
  height: 228px;
}

.group-product .card:hover .img_main {
  background: #fff;
  border-radius: 15px;
}

.group-product .card:hover .header1,
.group-product .card:hover .header1>span {
  color: #fff !important;
  bottom: 78px;
}

.group-product .card:hover .header2 {
  display: none;
}

.group-product .card:hover .header3 {
  display: block;
  color: #fff !important;
  bottom: 65px;
}

@media screen and (max-width: 768px) {
  .group-product .card .header1.bank {
    left: 7px !important;
  }

  .group-product .card .header2.bank,
  .group-product .card .header3.bank {
    bottom: 10px !important;
    left: 7px;
    right: 7px;
  }

  .group-product .card .img_main {
    height: 120px !important;
    width: 120px !important;
  }

  .group-product .card .header1 {
    font-weight: 900 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    left: 15px;
    bottom: 36px;
  }

  .group-product .card:hover .button {
    display: none !important;
  }

  .group-product .card .header2,
  .group-product .card .header3 {
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    left: 15px;
    bottom: 23px;
  }

  .group-product .card:hover .header1,
  .group-product .card:hover .header1>span {
    bottom: 36px;
  }

  .group-product .card:hover .header3 {
    bottom: 23px;
  }

  .group-product .card .img_bg {
    display: none !important;
  }

  .group-product .card .img_main {
    margin-left: auto;
    margin-right: auto;
  }
}</style>
